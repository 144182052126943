<template>
  <d-form-item
    :label="label"
    :error="error"
    :tooltip="tooltip"
  >
    <el-input
      v-model="model"
      class="font-normal"
      :autofocus="autofocus"
      :disabled="disabled"
      :placeholder="placeholder"
      :show-password="password"
      :required="required"
      :type="type"
      :clearable="clearable"
      :prefix-icon="prefixIcon"
      :size="size"
      :autocomplete="autocomplete"
      :rows="rows"
      :maxlength="maxlength"
      :show-word-limit="showWordLimit"
      :resize="resize"
      :readonly="readonly"
      :min="min"
      :max="max"
      :input-style="props.inputStyle"
      @focus="emits('focus', $event)"
      @input="emits('input', $event)"
      @keydown="$event.stopPropagation()"
    >
      <template
        v-if="$slots['prepend']"
        #prepend
      >
        <slot name="prepend" />
      </template>
      <template
        v-if="$slots['append']"
        #append
      >
        <slot name="append" />
      </template>
    </el-input>
  </d-form-item>
</template>

<script setup>
import DFormItem from '@/Components/ui/DFormItem.vue'
const model = defineModel()
const props = defineProps({
  label: String,
  tooltip: String,
  placeholder: String,
  error: String,
  disabled: Boolean,
  password: Boolean,
  required: Boolean,
  autofocus: Boolean,
  autocomplete: { type: String, default: 'none' },
  type: { type: String, default: 'text' },
  clearable: Boolean,
  prefixIcon: {
    type: String,
    default: ''
  },
  size: { type: String, default: 'large' },
  rows: { type: Number, default: 2 },
  maxlength: { type: Number, default: null },
  showWordLimit: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  resize: { type: String, default: null },
  min: { type: Number, default: null },
  max: { type: Number, default: null },
  inputStyle: {
    type: Object,
    default: null
  }
})

const emits = defineEmits(['focus', 'input'])
</script>
